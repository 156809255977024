<template>
  <v-app-bar app color="secondary">
    <router-link
      v-if="theme === 'dark'"
      to="/"
      style="padding: 5px; padding-right: 5px; text-decoration: none"
    >
      <template v-if="instance_settings.branding === 'acuity'">
        <v-img
          src="../../assets/acuity.png"
          max-width="130"
          contain
          style="float: left"
          class="ml-n2 mt-n1"
          to="/"
        ></v-img>
      </template>
      <template v-else>
        <v-img
          src="../../assets/certipath-nav-white.png"
          max-width="130"
          contain
          style="float: left"
          class="ml-0 mt-1"
          to="/"
        ></v-img>
      </template>
    </router-link>
    <router-link
      v-else
      to="/"
      style="padding: 0; padding-right: 5px; text-decoration: none"
    >
      <template v-if="instance_settings.branding === 'acuity'">
        <v-img
          src="../../assets/acuity.png"
          max-width="130"
          contain
          style="float: left"
          class="ml-n2 mt-n1"
        ></v-img>
      </template>

      <v-img
        src="../../assets/certipath-nav-white.png"
        max-width="130"
        contain
        style="float: left"
        class="ml-0 mt-1"
        to="/"
      ></v-img>
    </router-link>

    <v-spacer></v-spacer>

    <div>
      <v-btn
        color="primary"
        @click="toggle_token"
        small
        class="mr-4"
        style="display: none"
      >
        token
      </v-btn>
    </div>

    <UserDisplay></UserDisplay>
    <v-progress-linear
      :active="page_loading"
      :indeterminate="page_loading"
      absolute
      bottom
      color="primary accent-4"
      top
    ></v-progress-linear>
  </v-app-bar>
</template>

<style scoped></style>
<script>
import UserDisplay from './UserDisplay'

export default {
  props: ['decoded_token', 'theme'],
  components: { UserDisplay },
  data: function() {
    return {
      dialog: false,
      search: null,
      count: null,
      items: [],
      notifications: [],
      token_overlay: false
    }
  },
  mounted() {
    if (this.subdomain === 'shake') {
      this.$vuetify.theme.dark = this.$store.state.instance_settings.shake_dark
    } else {
      this.$vuetify.theme.dark = this.$store.state.instance_settings.amp_dark
    }
  },
  watch: {
    subdomain() {
      if (this.subdomain === 'amp') {
        //this.$vuetify.theme.dark = true;
      }
    }
  },
  computed: {
    instance_settings() {
      return this.$store.state.instance_settings
    },
    page_subtitle() {
      return this.$route.name
    },
    active_identity() {
      return this.$store.getters.active_identity
    },
    mapped_identities() {
      return this.$store.getters.mapped_identities
    },
    subdomain() {
      return this.$store.getters.subdomain
    },
    page_loading() {
      return this.$store.getters.page_loading
    },
    auth() {
      return this.$store.getters.ifAuthenticated
    },
    private_instance() {
      return this.$store.getters.instance.private
    },
    token_json() {
      return this.$store.getters.token_json
    },
    drawer: {
      get() {
        return this.$store.state.drawer
      },
      set(newValue) {
        this.$store.state.drawer = newValue
      }
    }
  },
  methods: {
    toggle_token() {
      this.$emit('toggle-token')
    },
    onLogout() {
      this.$store.dispatch('logout')
    }
  }
}
</script>
