import Vue from 'vue'
import Vuex from 'vuex'
import UserService from './UserService'
import router from '../router'
import jwt_decode from 'jwt-decode'
import axios from 'axios'
//import Cookie from "js-cookie";

Vue.use(Vuex)

axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN'
axios.defaults.withCredentials = true

export default new Vuex.Store({
  state: {
    snackbar: false,
    snackbar_text: '',
    snackbar_color: '',
    snackbar_timeout: 1000,
    amp_settings: [],
    csrftoken: null,
    amp_status: {
      email_address: null
    },
    crumbs: [],
    user_id: null,
    user_id_timestamp: null,
    access_token: null,
    refresh_token: null,
    page_loading: false,
    api_url: null,
    amp_url: null,
    cert_provider_domain: null,
    amp_server: {
      name: 'amp.certipath.io',
      url: null,
      identity_fields: ['uuid']
    },
    factors: [
      {
        id: 1,
        name: 'otp-sms',
        friendly_name: 'SMS OTP'
      },
      {
        id: 2,
        name: 'otp-yubikey',
        friendly_name: 'FIDO2/WebAuthn'
      },
      {
        id: 3,
        name: 'otp-email',
        friendly_name: 'Email OTP'
      },
      {
        id: 4,
        name: 'otp-totp',
        friendly_name: 'Authenticator App (TOTP)'
      }
    ],
    identity_providers: [],
    overlay: true,
    overlay_text: null,
    overlay_style: 'loading',
    login_status: null,
    token_loading: false,
    subdomain: null,
    auth_events: null,
    mapped_identities: [],
    active_identity: null,
    instance_settings: {
      amp_dark: null,
      shake_dark: null,
      branding: null,
      show_demo_apps: null,
      show_dev_links: null,
      hide_incomplete: null,
      require_verified: null,
      app_override_dark: null
    },
    registration_disabled: null,
    pizzly: {
      url: null,
      inception: null,
      integration: null,
      publishable_key: null
    },
    force_https: null
  },
  mutations: {
    snackbarClose(state) {
      state.snackbar = false
    },
    showSnackbar(state, payload) {
      state.snackbar = true
      state.snackbar_text = payload.text
      state.snackbar_color = payload.color
      state.snackbar_timeout = payload.timeout
    },
    updateInstanceSettings(state, settings) {
      state.instance_settings = settings
    },
    setSubdomain(state, subdomain) {
      state.subdomain = subdomain
    },
    authUser(state, userData) {
      state.overlay = true
      state.access_token = userData.access_token
      state.token_loading = false

      if (!state.user_id) {
        state.user_id = null
        state.user_id_timestamp = null
      }

      let shake_token = jwt_decode(state.access_token)
      state.auth_events = shake_token['auth_events']

      // Get amp user todo: handle 3rd party identities
      this.dispatch('getIdentities')
    },
    clearAuth(state) {
      state.refresh_token = null
      state.access_token = null
      state.userId = null
    }
  },
  actions: {
    showSnackbar({ commit }, message) {
      commit('showSnackbar', message)
    },
    updateStatus() {
      UserService.getAmpApi('settings').then((res) => {
        this.state.amp_settings = res.data
        this.state.identity_providers = res.data['active_providers']
        this.state.amp_status.email_address = res.data['email_address']
        if(this.state.amp_settings.status === 'open') {
          router.push({ name: 'Status' })
        }
      }).catch((err) => {
        console.log("Unable to connect to AMP API", err)
        this.state.overlay_text = "Unable to Connect"
      })
    },
    getIdentities() {
      let self = this
      // get all identities for the current shake token
      UserService.getAmpTokenApi('get')
        .then((res) => {
          this.state.mapped_identities = res.data

          if (res.data.length > 0) {
            this.state.overlay = true
            let uuid = this.state.user_id

            // uuids this token can login as
            let user_id_options = self.state.mapped_identities.map(
              (e) => e.user.uuid
            )

            if (!user_id_options.includes(uuid)) {
              uuid = user_id_options[0]
            }

            UserService.loginToAmp(uuid)
              .then((res) => {
                this.state.active_identity = res.data
                this.state.user_id = res.data.uuid
                this.state.overlay = false
                document.cookie = 'ampcsrftoken=' + res.data.csrf + '; path=/'
              })
              .catch((err) => {
                console.log(err)
              })
          } else {
            self.state.overlay = false
          }
        })
        .catch(function(error) {
          console.log(error)
          self.commit('authUser', { access_token: null })
        })
    },
    refreshIdentities() {
      let self = this
      // get all identities for the current shake token
      UserService.getAmpApi('users/details')
        .then((res) => {
          this.state.active_identity = res.data
          this.state.user_id = res.data.uuid
          this.state.overlay = false
        })
        .catch(function(error) {
          console.log(error)
          self.commit('authUser', { access_token: null })
        })
    },
    login({ commit }) {
      let self = this
      this.state.token_loading = true
      this.state.overlay = true
      UserService.createNewAuth()
        .then((res) => {
          self.state.token_loading = false
          document.cookie = 'shakecsrftoken=' + res.data.csrf
          self.state.overlay = false
          commit('authUser', {
            access_token: res.data.access
          })
        })
        .catch((error) => {
          console.log(error)
        })
    },
    logout({ commit, dispatch }) {
      commit('clearAuth')
      UserService.getAmpApi('logout').then(() => {})
      UserService.getApi('logout').then(() => {
        dispatch('login')
      })
      this.state.user_id = null
      this.state.mapped_identities = []
      this.state.active_identity = null
      this.state.overlay = true

      if (
        router.currentRoute.name !== 'About' &&
        router.currentRoute.name !== 'AppLogin'
      ) {
        router.push('/')
      }
    }
  },
  modules: {},
  getters: {
    amp_settings(state) {
      return state.amp_settings
    },
    has_verified_email(state) {
      return (
        state.active_identity.emails.filter(function(v) {
          return v.confirmed
        }).length > 0
      )
    },
    has_verified_phone(state) {
      return (
        state.sms_devices.filter(function(v) {
          return v.confirmed
        }).length > 0
      )
    },
    required_to_link(state) {
      let req = state.instance_settings.require_verified
      let email =
        state.active_identity.emails.filter(function(v) {
          return v.confirmed
        }).length > 0
      let phone =
        state.active_identity.sms_devices.filter(function(v) {
          return v.confirmed
        }).length > 0

      if (req === 'either') {
        if (email || phone) {
          return ''
        } else {
          return 'an email address or phone number'
        }
      } else if (req === 'both') {
        if (email && phone) {
          return ''
        } else if (email) {
          return 'a phone number'
        } else if (phone) {
          return 'an email address'
        }
      } else if (req === 'phone') {
        if (!phone) {
          return 'a phone number'
        }
      } else if (req === 'email') {
        if (!email) {
          return 'an email address'
        }
      }
      return ''
    },
    crumbs(state) {
      return [{ text: 'Home', disabled: false, href: '/' }].concat(state.crumbs)
    },
    ifAuthenticated(state) {
      try {
        //jwt_decode(state.access_token);
        const { exp } = jwt_decode(state.access_token)
        if (Date.now() >= exp * 1000) {
          return false
        }
      } catch (err) {
        return false
      }
      return true
    },
    cert_provider_domain(state) {
      return state.cert_provider_domain
    },
    mapped_identities(state) {
      return state.mapped_identities
    },
    unmapped_identities(state) {
      let token = jwt_decode(state.access_token)
      return token.auth_events.filter(function(v) {
        return (
          state.mapped_identities.filter(function(v2) {
            return v2.uid === v.user && v2.provider.name === v.provider
          }).length === 0 &&
          state.mapped_identities.filter(function(v2) {
            return v2.user.uuid.length > 10
          }).length !== 0
        )
      })
    },
    alternate_identities(state) {
      if (!state.active_identity || !state.mapped_identities.length > 0) {
        return []
      }
      let result = {}
      return [
        ...new Set(
          state.mapped_identities
            .filter(function(v) {
              return v.user.uuid !== state.active_identity.uuid
            })
            .map((e) => ({
              display_name: e.user.display_name,
              uuid: e.user.uuid
            }))
        )
      ].filter(function(e) {
        if (result[e.uuid]) {
          return false
        }
        result[e.uuid] = true
        return true
      })
    },
    active_identity(state) {
      return state.active_identity
    },
    subdomain(state) {
      return state.subdomain
    },
    amp_server(state) {
      return state.amp_server
    },
    identity_providers(state) {
      return state.identity_providers
    },
    factors(state) {
      return state.factors
    },
    amp_url(state) {
      return state.amp_url
    },
    access_token(state) {
      return state.access_token
    },
    api_url(state) {
      return state.api_url
    },
    page_loading(state) {
      return state.page_loading
    },
    overlay(state) {
      return state.overlay
    },
    overlay_text(state) {
      return state.overlay_text
    },
    login_status(state) {
      return state.login_status
    },
    token_loading(state) {
      return state.token_loading
    }
  }
})
