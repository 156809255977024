import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

const routeOptions = [
  {
    path: '/',
    name: 'About',
    amp: false,
    meta: { amp: true, requiresAuth: false }
  },
  {
    path: '/applications',
    name: 'Applications',
    amp: true,
    meta: { amp: true, requiresAuth: true }
  },
  {
    path: '/demos',
    name: 'Demos',
    amp: true,
    meta: { amp: true, requiresAuth: true }
  },
  {
    path: '/History',
    name: 'History',
    amp: true,
    meta: { amp: true, requiresAuth: true }
  },
  {
    path: '/Instance',
    name: 'Instance',
    amp: true,
    meta: { amp: true, requiresAuth: true }
  },
  {
    path: '/Groups',
    name: 'Groups',
    amp: true,
    meta: { amp: true, requiresAuth: true }
  },
  {
    path: '/Users',
    name: 'UserManagement',
    meta: { amp: true, requiresAuth: true }
  },
  {
    path: '/application/:id',
    name: 'Application',
    meta: { amp: true, requiresAuth: true }
  },
  {
    path: '/account',
    name: 'Account',
    amp: true,
    meta: { amp: true, requiresAuth: true }
  },
  {
    path: '/activate/:uuid',
    name: 'Activate',
    meta: { amp: true, requiresAuth: false }
  },
  { path: '/accounts/login/', name: 'AppLogin', amp: false },
  { path: '*', name: 'PageNotFound', amp: false },
  {
    path: '/status',
    name: 'Status',
    amp: true,
    meta: { amp: true, requiresAuth: false }
  },
  {
    path: '/docs',
    name: 'Docs',
    amp: true,
    meta: { amp: true, requiresAuth: false }
  },
  {
    path: '/reactivate',
    name: 'Reactivate',
    amp: true,
    meta: { amp: true, requiresAuth: false }
  }
]

const routes = routeOptions.map((route) => {
  return {
    ...route,
    component: () =>
      import(/* webpackChunkName: "[request]" */ `../views/${route.name}.vue`)
  }
})

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (!store.getters.subdomain) {
    // first navigation can go anywhere
    if (to.matched[0].name !== 'AppLogin') {
      // Not logging into an app
      if (window.location.hostname === 'shake.certipath.io') {
        // if they got here from shake redirect them to amp
        window.location = 'https://amp.certipath.io/'
      }
      store.commit('setSubdomain', 'amp')
    } else {
      store.commit('setSubdomain', 'shake')
    }
  } else {
    // dont let users navigate between amp/shake
    if (
      to.matched[0].name !== 'AppLogin' &&
      store.getters.subdomain !== 'amp'
    ) {
      //return false;
    } else if (
      to.matched[0].name === 'AppLogin' &&
      store.getters.subdomain !== 'shake'
    ) {
      //return false;
    }
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.ifAuthenticated) {
      next({
        path: '/',
        params: { nextUrl: to.fullPath }
      })
    } else {
      let user = JSON.parse(localStorage.getItem('user'))
      if (to.matched.some((record) => record.meta.requiresAdmin)) {
        if (user.is_admin === 1) {
          next()
        } else {
          next({ name: 'userboard' })
        }
      } else {
        next()
      }
    }
  } else if (to.matched.some((record) => record.meta.guest)) {
    if (localStorage.getItem('jwt') == null) {
      next()
    } else {
      next({ name: 'userboard' })
    }
  } else {
    next()
  }
})

export default router
