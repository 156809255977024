<template>
  <v-alert
    elevation="2"
    outlined
    class="mb-2 pa-0 pl-2"
    color="success"
    style="font-size: 0.8em; width: 100%"
  >
    <v-avatar size="30" class="mr-2">
      <v-icon color="success">mdi-card-account-details-outline</v-icon>
    </v-avatar>
    <template v-if="cert_results">
      <strong>{{ cert_results.short_name }}</strong>
      <span class="ml-2" v-if="cert_results.serial"
        >(#{{ cert_results.serial }})</span
      >

      <v-spacer></v-spacer>Issued By {{ cert_results.issuer_short_name }}
      <br />
      <span style="font-size: 0.85em; color: #666">{{
        event.time | moment('from', 'now')
      }}</span>
    </template>
    <template v-else>
      <v-progress-circular
        indeterminate
        size="35"
        color="secondary"
      ></v-progress-circular>
    </template>
  </v-alert>
</template>

<script>
import UserService from '../../../../store/UserService'

export default {
  name: 'CertificateSmallTile',
  props: ['event'],
  data: function() {
    return {
      cert_results: null
    }
  },
  mounted() {
    UserService.postApi('auth-events/verify/', {
      uid: this.event.user,
      timestamp: this.event.time,
      provider: this.event.provider
    }).then((res) => {
      // console.log(res);
      this.cert_results = res.data.response.certificate
    })
  }
}
</script>

<style scoped></style>
