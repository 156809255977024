<template>
  <v-alert
    elevation="2"
    outlined
    class="mb-2 pa-0 pl-2"
    color="success"
    style="font-size: 0.8em; width: 100%"
  >
    <v-avatar style="float: left" class="mr-4">
      <v-icon>mdi-usb-flash-drive-outline</v-icon>
    </v-avatar>

    <strong>{{ event.provider }} </strong>

    <span style="font-size: 0.85em; color: #666">{{
      event.time | moment('from', 'now')
    }}</span>
  </v-alert>
</template>

<script>
export default {
  name: 'Fido2SmallTile',
  props: ['event']
}
</script>

<style scoped></style>
