<template>
  <v-alert
    elevation="2"
    outlined
    class="mb-2 pa-0 pl-2"
    color="success"
    style="font-size: 0.8em; width: 100%"
  >
    <v-avatar
      style="float: left"
      v-if="event.avatar_url"
      class="mr-4 mt-2"
      width="30"
      height="30"
    >
      <img :src="event.avatar_url" alt="" />
    </v-avatar>

    <strong>{{ event.user }}</strong>

    <v-spacer></v-spacer>{{ event.provider }}

    <span style="font-size: 0.85em; color: #666">{{
      event.time | moment('from', 'now')
    }}</span>
  </v-alert>
</template>

<script>
export default {
  name: 'ThirdPartySmallTile',
  props: ['event']
}
</script>

<style scoped></style>
