import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

let LIGHT_THEME = {
  primary: '#053768',
  secondary: '#7192B0',
  accent: '#253967'
}
export default new Vuetify({
  theme: {
    dark: true,
    options: {
      customProperties: true
    },
    themes: {
      light: LIGHT_THEME
    }
  }
})
