<template>
  <v-app :class="theme">
    <v-overlay :value="token_overlay">
      <v-card class="ma-4" :loading="token_loading">
        <v-card-title>Awesome token with custom auth claims </v-card-title>
        <v-card-text>
          <pre class="prettyprint" style="font-size: 0.75em">{{
            decoded_token
          }}</pre>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="token_overlay = !token_overlay">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-overlay>

    <v-overlay v-if="overlay" z-index="9001" opacity=".5">
      <v-card
        style="width: 1200px; height: 1200px; background: transparent"
        flat
      >
        <v-overlay v-if="overlay_text" style="text-align:center">
          <h5 class="text-h5 font-weight-light primary--text">
            <span>{{ overlay_text }}</span>
          </h5>
          <br />
          <v-btn color="primary" @click="refresh_window">
            Try Again
          </v-btn>
        </v-overlay>

        <vue-particles
          color="#053768"
          :particleOpacity="0.8"
          :particlesNumber="70"
          shapeType="circle"
          :particleSize="4"
          linesColor="#eeeeee"
          :linesWidth="1"
          :lineLinked="true"
          :lineOpacity="0.7"
          :linesDistance="130"
          :moveSpeed="3"
          :hoverEffect="true"
          hoverMode="grab"
          :clickEffect="true"
          clickMode="push"
          style="height: 1200px"
        >
        </vue-particles>
      </v-card>
    </v-overlay>

    <Header @toggle-token="toggle_token" :theme="theme" />

    <v-main>
      <Breadcrumbs v-if="show_breadcrumbs" class="mt-0" />

      <router-view></router-view>
    </v-main>

    <v-snackbar
      bottom
      right
      :value="updateExists"
      :timeout="-1"
      color="primary"
    >
      An update is available
      <v-btn text @click="refreshApp"> Update </v-btn>
    </v-snackbar>

    <v-snackbar
      bottom
      right
      :value="snackbar"
      :timeout="2000"
      :color="snackbar_color"
      @input="snackbarClose"
    >
      {{ snackbar_text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-footer
      padless
      fixed
      style="
        border-top: 1px solid #ddd;
        z-index: 9100;
        text-align: center;
        font-size: 0.85em;
      "
    >
      <v-card class="flex" flat tile style="color: #e2e3e3">
        &copy; {{ new Date().getFullYear() }} —
        <template v-if="instance_settings.branding === 'acuity'">
          Powered by
          <a
            href="https://certipath.com"
            target="_blank"
            style="text-decoration: none; font-weight: bold"
            >certipath.io</a
          >
        </template>
        <template v-else> CertiPath, Inc. </template>
        <v-spacer></v-spacer>
      </v-card>
    </v-footer>
  </v-app>
</template>
<style>
#app.light {
  background: #eee !important;
  background: linear-gradient(141deg, #fff 0%, #eee 50%) !important;
}
.v-footer,
.v-footer .v-card {
  background: none !important;
  border: none !important;
  box-shadow: none !important;
}

#app.dark {
  background: #133e9c !important;
  background: linear-gradient(
    141deg,
    rgba(2, 5, 13, 1) 00%,
    #253967 150%
  ) !important;
}
.v-footer,
.v-footer .v-card {
  background: none !important;
  border: none !important;
  box-shadow: none !important;
}
*:not(input) {
  caret-color: transparent;
}
input {
  caret-color: #7192b0;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #f8f8f8;
}
</style>

<script>
import Header from './components/base/Header'
import update from './components/mixins/update'
import jwt_decode from 'jwt-decode'
import Breadcrumbs from './components/base/Breadcrumbs'
import { shakeApi, ampApi } from './store/axios'

if (
  location.protocol !== 'https:' &&
  process.env.VUE_APP_FORCE_HTTPS === 'True'
) {
  location.protocol = 'https:'
}

export default {
  name: 'App',
  title: 'certipath.io',
  components: {
    Breadcrumbs,
    Header
  },

  data: () => ({
    token_overlay: false
  }),
  mixins: [update],
  computed: {
    snackbar: {
      get() {
        return this.$store.state.snackbar
      },
      set(value) {
        this.$store.state.snackbar = value
      }
    },
    snackbar_text() {
      return this.$store.state.snackbar_text
    },
    snackbar_color() {
      return this.$store.state.snackbar_color
    },
    snackbar_timeout() {
      return this.$store.state.snackbar_timeout
    },
    instance_settings() {
      return this.$store.state.instance_settings
    },
    show_breadcrumbs() {
      return (
        (!this.overlay || this.$route.path !== '/') &&
        this.$route.path !== '/accounts/login/'
      )
    },
    theme() {
      return this.$vuetify.theme.dark ? 'dark' : 'light'
    },
    overlay() {
      return this.$store.getters.overlay
    },
    overlay_text() {
      return this.$store.getters.overlay_text
    },
    overlay_style() {
      return this.$store.getters.overlay_style
    },
    decoded_token() {
      if (this.$store.getters.access_token) {
        return jwt_decode(this.$store.getters.access_token)
      } else {
        return null
      }
    },
    token_loading() {
      return this.$store.getters.token_loading
    }
  },
  methods: {
    snackbarClose(val) {
      this.$store.commit('snackbarClose', val)
    },
    toggle_token() {
      this.token_overlay = !this.token_overlay
    },
    refresh_window() {
      window.location.reload()
    }
  },
  created() {
    this.$store.state.api_url = this.$config.SHAKE_API_BASE + '/api/v1/'
    this.$store.state.amp_url = this.$config.AMP_API_BASE + '/api/v1/'

    shakeApi.defaults.baseURL = this.$store.state.api_url
    ampApi.defaults.baseURL = this.$store.state.amp_url

    this.$store.state.cert_provider_domain = this.$config.APACHE_URL
    this.$store.state.amp_server.url = this.$config.AMP_API_BASE
    this.$store.state.registration_disabled =
      this.$config.DISABLE_PUBLIC_REGISTRATION === 'True'
    this.$store.state.force_https = false

    this.$store.state.pizzly = {
      url: this.$config.PIZZLY_URL,
      inception: this.$config.PIZZLY_INCEPTION_URL,
      integration: this.$config.PIZZLY_INTEGRATION,
      publishable_key: '1eff321f647876de6948'
    }

    this.$store.state.instance_settings = {
      amp_dark: this.$config.AMP_DARK === 'True',
      shake_dark: this.$config.SHAKE_DARK === 'True',
      branding: this.$config.BRANDING,
      show_demo_apps: this.$config.SHOW_DEMO_APPS === 'True',
      show_dev_links: this.$config.SHOW_DEV_LINKS === 'True',
      hide_incomplete: this.$config.HIDE_INCOMPLETE === 'True',
      require_verified: 'either',
      app_override_dark: true
    }
    this.$store.dispatch('login')
    this.$store.dispatch('updateStatus')
  }
}
</script>
