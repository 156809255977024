import axios from 'axios'
import store from './index'

// For common config
axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN'
axios.defaults.withCredentials = true

const shakeApi = axios.create({
  baseURL: null,
  xsrfCookieName: 'shakecsrftoken',
  xsrfHeaderName: 'X-CSRFTOKEN'
})
shakeApi.interceptors.response.use(
  function(response) {
    return response
  },
  function(error) {
    if (error.response.status === 401) {
      store.state.overlay = true
      store.dispatch('logout')
    } else {
      console.error(error)
    }
    return Promise.reject(error)
  }
)

const ampApi = axios.create({
  baseURL: null,
  xsrfCookieName: 'ampcsrftoken',
  xsrfHeaderName: 'X-CSRFTOKEN'
})
ampApi.interceptors.response.use(
  function(response) {
    return response
  },
  function(error) {
    if (error.response.status === 401) {
      store.state.overlay = true
      store.dispatch('logout')
    } else {
      console.error(error)
    }
    return Promise.reject(error)
  }
)

export { shakeApi, ampApi }
