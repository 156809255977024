<template>
  <div>
    <template v-if="!overlay && active_identity">
      <v-dialog v-if="$vuetify.breakpoint.smAndDown" fullscreen v-model="menu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark text v-bind="attrs" v-on="on">
            <v-icon class="mr-1" small color="primary">mdi-account</v-icon>
            {{ display_name }}
            <v-icon class="mr-n3 ml-1" color="#1073ad">mdi-menu-down</v-icon>
          </v-btn>
        </template>

        <v-card style="width: 100%" class="mt-0">
          <v-toolbar dark color="secondary">
            <v-toolbar-title>
              <v-icon class="ma-0 pa-0 mr-2" large color="primary"
                >mdi-account</v-icon
              >
              {{ active_identity.first_name }}
              {{ active_identity.last_name }} </v-toolbar-title
            ><v-spacer></v-spacer
            ><v-btn icon dark @click="menu = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <v-sheet style="height: 3px" color="accent"></v-sheet>

          <v-card-text class="ma-0 pa-0">
            <v-list subheader>
              <v-subheader>Your Account</v-subheader>
              <v-list-item-group>
                <v-list-item
                  to="/Account"
                  @click="
                    menu = false
                    selected = 0
                  "
                >
                  <v-list-item-avatar
                    ><v-icon> mdi-shield-account </v-icon></v-list-item-avatar
                  >
                  <v-list-item-content>
                    <v-list-item-title>Account Management</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                  to="/History"
                  @click="
                    menu = false
                    selected = 0
                  "
                >
                  <v-list-item-avatar
                    ><v-icon> mdi-history </v-icon></v-list-item-avatar
                  >
                  <v-list-item-content>
                    <v-list-item-title>History</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item @click="logout">
                  <v-list-item-avatar
                    ><v-icon> mdi-logout </v-icon></v-list-item-avatar
                  >
                  <v-list-item-content>
                    <v-list-item-title>Sign Out</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
              <template>
                <v-subheader
                  v-if="show_applications || show_users || admin_owner"
                  >Administration</v-subheader
                >
                <v-list-item-group>
                  <v-list-item
                    v-if="show_applications"
                    to="/Applications"
                    @click="
                      menu = false
                      selected = 0
                    "
                  >
                    <v-list-item-avatar
                      ><v-icon>
                        mdi-application-cog
                      </v-icon></v-list-item-avatar
                    >
                    <v-list-item-content>
                      <v-list-item-title>Applications</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
                <v-list-item-group v-if="show_users">
                  <v-list-item
                    to="/Users"
                    @click="
                      menu = false
                      selected = 0
                    "
                  >
                    <v-list-item-avatar
                      ><v-icon> mdi-account-cog </v-icon></v-list-item-avatar
                    >
                    <v-list-item-content>
                      <v-list-item-title>User Management</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>

                <v-list-item-group v-if="admin_owner">
                  <v-list-item
                    to="/Instance"
                    @click="
                      menu = false
                      selected = 0
                    "
                  >
                    <v-list-item-avatar
                      ><v-icon> mdi-cog </v-icon></v-list-item-avatar
                    >
                    <v-list-item-content>
                      <v-list-item-title>Instance Settings</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </template>
            </v-list>
            <v-list class="pb-0">
              <v-alert
                dense
                elevation="0"
                v-if="alternate_identities.length > 0"
                class="mr-0 ml-0 mb-2 pa-0 pl-0"
              >
                <v-list dense class="mt-0 pt-0 mb-0 pb-0">
                  <v-subheader class="mt-0 pt-0"
                    >Alternate Identities:
                  </v-subheader>

                  <v-list-item-group color="primary">
                    <v-list-item
                      v-for="item in alternate_identities"
                      :key="item.uuid"
                      @click="switch_identity(item.uuid)"
                    >
                      <v-list-item-icon>
                        <v-icon>mdi-account-switch</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.display_name"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-alert>

              <v-list class="mt-0 pt-0 mb-0 pb-0">
                <v-subheader>Active Credentials: </v-subheader>

                <v-list-item v-for="(e, i) in user_auth_events" :key="i">
                  <template v-if="e.provider === 'certipath-certificate'">
                    <CertificateSmallTile :event="e"></CertificateSmallTile>
                  </template>
                  <template v-else-if="e.provider === 'fido2-device'">
                    <Fido2SmallTile :event="e"></Fido2SmallTile>
                  </template>
                  <template v-else>
                    <ThirdPartySmallTile :event="e"></ThirdPartySmallTile>
                  </template>
                </v-list-item>
              </v-list>
            </v-list>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-menu v-else v-model="menu" min-width="350">
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark text v-bind="attrs" v-on="on">
            <v-icon class="mr-1" small color="primary">mdi-account</v-icon>
            {{ display_name }}
            <v-icon class="mr-n3 ml-1" color="#1073ad">mdi-menu-down</v-icon>
          </v-btn>
        </template>

        <v-card style="width: 100%; background: transparent" class="mt-n2">
          <v-toolbar
            dense
            dark
            style="background: rgba(255, 255, 255, 0.1); padding-top: 5px"
          >
            <v-toolbar-title>
              <v-icon class="ma-0 pa-0 mr-2" large color="white"
                >mdi-account</v-icon
              >

              {{ active_identity.first_name }}
              {{ active_identity.last_name }}
            </v-toolbar-title>
          </v-toolbar>

          <v-sheet style="height: 3px" color="accent"></v-sheet>

          <v-card-text class="ma-0 pa-0">
            <v-list subheader dense>
              <v-subheader>Your Account</v-subheader>
              <v-list-item-group>
                <v-list-item
                  to="/Account"
                  @click="
                    menu = false
                    selected = 0
                  "
                >
                  <v-list-item-avatar
                    ><v-icon> mdi-shield-account </v-icon></v-list-item-avatar
                  >
                  <v-list-item-content>
                    <v-list-item-title>Account Management</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                  to="/History"
                  @click="
                    menu = false
                    selected = 0
                  "
                >
                  <v-list-item-avatar
                    ><v-icon> mdi-history </v-icon></v-list-item-avatar
                  >
                  <v-list-item-content>
                    <v-list-item-title>History</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item @click="logout">
                  <v-list-item-avatar
                    ><v-icon> mdi-logout </v-icon></v-list-item-avatar
                  >
                  <v-list-item-content>
                    <v-list-item-title>Sign Out</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
              <template>
                <v-subheader
                  v-if="show_applications || show_users || admin_owner"
                  >Administration</v-subheader
                >
                <v-list-item-group v-if="show_applications">
                  <v-list-item
                    to="/Applications"
                    @click="
                      menu = false
                      selected = 0
                    "
                  >
                    <v-list-item-avatar
                      ><v-icon>
                        mdi-application-cog
                      </v-icon></v-list-item-avatar
                    >
                    <v-list-item-content>
                      <v-list-item-title>Applications</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </template>

              <v-list-item-group v-if="show_users">
                <v-list-item
                  to="/Users"
                  @click="
                    menu = false
                    selected = 0
                  "
                >
                  <v-list-item-avatar
                    ><v-icon> mdi-account-cog </v-icon></v-list-item-avatar
                  >
                  <v-list-item-content>
                    <v-list-item-title>User Management</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>

              <v-list-item-group v-if="admin_owner">
                <v-list-item
                  to="/Instance"
                  @click="
                    menu = false
                    selected = 0
                  "
                >
                  <v-list-item-avatar
                    ><v-icon> mdi-cog </v-icon></v-list-item-avatar
                  >
                  <v-list-item-content>
                    <v-list-item-title>Instance Settings</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
            <v-list class="pb-0">
              <v-alert
                dense
                elevation="0"
                v-if="alternate_identities.length > 0"
                class="mr-0 ml-0 mb-2 pa-0 pl-0"
              >
                <v-list dense class="mt-0 pt-0 mb-0 pb-0">
                  <v-subheader class="mt-0 pt-0"
                    >Alternate Identities:
                  </v-subheader>

                  <v-list-item-group color="primary">
                    <v-list-item
                      v-for="item in alternate_identities"
                      :key="item.uuid"
                      @click="switch_identity(item.uuid)"
                    >
                      <v-list-item-icon>
                        <v-icon>mdi-account-switch</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.display_name"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-alert>

              <v-list class="mt-0 pt-0 mb-0 pb-0">
                <v-subheader>Active Credentials: </v-subheader>

                <v-list-item v-for="(e, i) in user_auth_events" :key="i">
                  <template v-if="e.provider === 'certipath-certificate'">
                    <CertificateSmallTile :event="e"></CertificateSmallTile>
                  </template>
                  <template v-else-if="e.provider === 'fido2-device'">
                    <Fido2SmallTile :event="e"></Fido2SmallTile>
                  </template>
                  <template v-else>
                    <ThirdPartySmallTile :event="e"></ThirdPartySmallTile>
                  </template>
                </v-list-item>
                <p class="text-center mb-0" style="font-size: 0.85em">
                  Interface v1.4.0
                </p>
              </v-list>
            </v-list>
          </v-card-text>
        </v-card>
      </v-menu>
    </template>
  </div>
</template>

<script>
import jwt_decode from 'jwt-decode'
import Pizzly from 'pizzly-js'
import UserService from '../../store/UserService'
import ThirdPartySmallTile from './tiles/small/ThirdPartySmallTile'
import CertificateSmallTile from './tiles/small/CertificateSmallTile'
import Fido2SmallTile from './tiles/small/Fido2SmallTile'

export default {
  name: 'UserDisplay',
  components: {
    Fido2SmallTile,
    CertificateSmallTile,
    ThirdPartySmallTile
  },
  props: [],

  data: () => ({
    selected: null,
    menu: false,
    message: false,
    hints: true,
    tab: 0
  }),
  methods: {
    switch_identity(uuid) {
      this.$store.state.overlay = true
      this.$store.state.user_id = this.alternate_identities.filter(function(e) {
        return e.uuid === uuid
      })[0].uuid
      this.$store.dispatch('login')
      //this.$store.dispatch('getIdentities')
    },
    logout() {
      this.menu = false
      this.$store.state.overlay = true
      this.$store.dispatch('logout')
    },
    groupBy(arr, prop) {
      const map = new Map(Array.from(arr, (obj) => [obj[prop], []]))
      arr.forEach((obj) => map.get(obj[prop]).push(obj))
      return Array.from(map.values())
    },
    connect_provider(provider) {
      this.$pizzly = new Pizzly({
        host: this.$store.state.pizzly.url,
        publishableKey: this.$store.state.pizzly.publishable_key
      })
      this.$store.state.overlay = true
      this.$store.state.token_loading = true
      const prov = this.$pizzly.integration(provider.name)

      prov
        .connect()
        .then((data) => {
          this.connectSuccess(provider, data)
        })
        .catch(() => {
          this.$store.state.overlay = false
        })
    },
    connectSuccess: function(provider, data) {
      let self = this
      // On success, we update the user object
      UserService.addAuth(provider, data.authId).then((res) => {
        self.$store.commit('authUser', {
          access_token: res.data.access
        })
      })
    }
  },
  computed: {
    admin_owner() {
      if (!this.active_identity || !this.active_identity.memberships) {
        return false
      }
      let self = this
      return (
        this.active_identity.memberships.filter(function(v) {
          return (
            v.organization.is_super &&
            v.organization.owner === self.active_identity.uuid
          )
        }).length === 1
      )
    },
    show_users() {
      if (!this.active_identity || !this.active_identity.memberships) {
        return false
      }
      // show users if in super OR can manage_users for any org
      return (
        this.active_identity.memberships.filter(function(v) {
          return v.manage_users
        }).length > 0
      )
    },
    show_applications() {
      if (!this.active_identity || !this.active_identity.memberships) {
        return false
      }
      return (
        this.active_identity.memberships.filter(function(v) {
          return (
            (v.organization.is_super || v.organization.manage_applications) &&
            v.manage_applications
          )
        }).length > 0
      )
    },
    instance_settings() {
      return this.$store.state.instance_settings
    },
    overlay() {
      return this.$store.getters.overlay
    },
    subdomain() {
      return this.$store.getters.subdomain
    },
    decoded_token() {
      if (this.$store.getters.access_token) {
        return jwt_decode(this.$store.getters.access_token)
      } else {
        return null
      }
    },
    auth_events() {
      if (!this.decoded_token) {
        return []
      } else {
        return this.decoded_token.auth_events
      }
    },
    user_auth_events() {
      let self = this
      return this.auth_events.filter(function(v) {
        return (
          self.mapped_identities.filter(function(v2) {
            return (
              v2.uid === v.user &&
              v2.provider.name === v.provider &&
              v2.user.uuid === self.$store.state.user_id
            )
          }).length > 0
        )
      })
    },
    mapped_identities() {
      return this.$store.getters.mapped_identities
    },
    inactive_accounts() {
      let self = this
      return this.active_identity.provider_accounts.filter(function(v2) {
        return (
          self.auth_events.filter(function(v) {
            return v2.uid === v.user && v2.provider.name === v.provider
          }).length === 0
        )
      })
    },
    inactive_accounts_by_provider() {
      return this.groupBy(
        this.inactive_accounts.map((e) => ({
          id: e.provider.id,
          provider: e.provider,
          account: e
        })),
        'id'
      )
    },
    alternate_identities() {
      return this.$store.getters.alternate_identities
    },
    active_identity() {
      return this.$store.getters.active_identity
    },
    primary_email() {
      if (!this.active_identity) {
        return null
      }
      let primary = this.active_identity.emails.filter(function(v) {
        return v.is_primary
      })
      if (primary.length > 0) {
        return primary[0].email
      } else {
        return null
      }
    },
    display_name() {
      if (!this.active_identity) {
        return null
      }

      if (this.active_identity) {
        let primary = this.active_identity.emails.filter(function(v) {
          return v.is_primary
        })

        if (this.active_identity.first_name) {
          return this.active_identity.first_name
        } else if (primary.length > 0) {
          return primary[0].email
        }
      } else {
        return ''
      }
      return null
    }
  }
}
</script>

<style scoped></style>
