import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueParticles from 'vue-particles'
import titleMixin from './components/mixins/titleMixin'
import update from './components/mixins/update'
import i18n from './i18n'

Vue.config.productionTip = false
Vue.use(require('vue-moment'))
Vue.use(require('u2f-api-polyfill'))

Vue.use(VueParticles)
Vue.mixin(titleMixin)
Vue.mixin(update)

let DARK_THEME = {}
fetch('/config.json')
  .then((response) => response.json())
  .then((config) => {
    Vue.prototype.$config = config
    if(Vue.prototype.$config.BRANDING === 'certipath') {
      DARK_THEME = {
        accent: '#7192B0',
        error: '#aa2123',
        primary: '#7192B0',
        secondary: '#253967',
        background:
          'linear-gradient(141deg, rgba(2,5,13,1) 0%, rgba(19,62,156,1) 100%);'
      }
    }else{
      DARK_THEME = {
        accent: '#aa2123',
        error: '#aa2123',
        primary: '#1073AD', // light blue
        //primary: "#F19e1f", // gold
        secondary: '#253967',
        background:
          'linear-gradient(141deg, rgba(2,5,13,1) 0%, rgba(19,62,156,1) 100%);'
      }
    }

    // this applies appropriate changes to the dark theme
    vuetify.framework.theme.themes.dark = {
      ...vuetify.framework.theme.themes.dark,
      ...DARK_THEME
    }

    new Vue({
      router,
      store,
      vuetify,
      i18n,
      render: (h) => h(App)
    }).$mount('#app')
  })
