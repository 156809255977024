<template>
  <v-card style="background: #535b69; border-radius: 0" flat class="pa-0 ma-0">
    <v-sheet style="height: 3px" color="accent"></v-sheet>
    <div
      class="ma-0"
      style="
        width: 100%;
        border-radius: 0;
        padding: 1px;
        padding-top: 1px;
        padding-left: 5px;
      "
    >
      <v-breadcrumbs :items="crumbs" class="ml-1 pa-0" divider=">">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item
            :to="item.href"
            :disabled="item.disabled"
            v-if="item.text !== 'Home'"
          >
            <span style="color: white !important">{{
              item.text.toUpperCase()
            }}</span>
          </v-breadcrumbs-item>
          <v-breadcrumbs-item :to="item.href" v-else>
            <v-icon style="color: white">mdi-home</v-icon>
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'Breadcrumbs',
  computed: {
    crumbs: function() {
      return this.$store.getters.crumbs
    }
  }
}
</script>

<style scoped></style>
