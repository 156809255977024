//import axios from "axios";
import authHeader from './AuthHeader'
import store from './index'
import axios from 'axios'
import { shakeApi, ampApi } from './axios'

axios.defaults.withCredentials = true

class UserService {
  getAmpTokenApi(path, params = null) {
    path = 'token/' + path

    if (path[path.length - 1] !== '/' && path.indexOf('?') === -1) {
      path += '/'
    }

    if (params) {
      let test = new URLSearchParams(params)
      path += '?' + test
    }

    return ampApi.get(path, {
      withCredentials: false,
      headers: { Authorization: 'Bearer ' + store.state.access_token }
    })
  }

  getAmpApi(path, params = null) {
    if (path[path.length - 1] !== '/' && path.indexOf('?') === -1) {
      path += '/'
    }
    if (params) {
      let test = new URLSearchParams(params)
      path += '?' + test
    }
    return ampApi.get(path)
  }

  deleteAmpApi(path) {
    if (path[path.length - 1] !== '/' && path.indexOf('?') === -1) {
      path += '/'
    }
    return ampApi.delete(path)
  }

  postAmpApi(path, data) {
    if (path[path.length - 1] !== '/' && path.indexOf('?') === -1) {
      path += '/'
    }
    return ampApi.post(path, data)
  }

  patchAmpApi(path, data) {
    if (path[path.length - 1] !== '/' && path.indexOf('?') === -1) {
      path += '/'
    }
    return ampApi.patch(path, data)
  }

  loginToAmp(uuid) {
    return ampApi.get('token/login/?uuid=' + uuid, {
      withCredentials: true,
      headers: { Authorization: 'Bearer ' + store.state.access_token }
    })
  }

  getApi(path, params = null) {
    if (path[path.length - 1] !== '/' && path.indexOf('?') === -1) {
      path += '/'
    }

    if (params) {
      let test = new URLSearchParams(params)
      path += '?' + test
    }

    return shakeApi.get(path)
  }

  getApiNoAuth(path, params = null) {
    if (path[path.length - 1] !== '/' && path.indexOf('?') === -1) {
      path += '/'
    }
    if (params) {
      let test = new URLSearchParams(params)
      path += '?' + test
    }

    return shakeApi.get(path, {
      withCredentials: false
    })
  }

  postApi(path, data) {
    if (path[path.length - 1] !== '/' && path.indexOf('?') === -1) {
      path += '/'
    }
    return shakeApi.post(path, data)
  }

  getPizzly(auth_id) {
    return shakeApi.get('get_pizzly?auth_id=' + auth_id)
  }

  createNewAuth() {
    return shakeApi.get('new_token/').catch((err) => {
      console.log("Unable to connect to SHAKE API", err)
      store.state.overlay_text = "Unable to Connect"
    })
  }

  addAuth(provider, authId) {
    // var url = new URL(store.getters.api_url);
    var url = 'auth-events/add/'
    let data = {
      authId: authId,
      provider: provider.id
    }
    return shakeApi.post(url, data)
  }

  removeAuth(auth) {
    var url = 'auth-events/remove/'
    let data = {
      user: auth.user,
      provider: auth.provider
    }
    return shakeApi.post(url, data)
  }

  submitAuth(uuid, params, events, challenge_id, selected_org) {
    let url = 'auth-events/submit/' + params
    let data = {
      events: events,
      org: selected_org,
      uuid: uuid,
      challenge_id: challenge_id
    }
    return shakeApi.post(url, data).then(() => {
      window.location =
        store.getters.api_url.slice(0, -7) + 'o/authorize/' + params
    }).catch((err) => {
      store.dispatch('showSnackbar', {
        text: err.response.data,
        color: 'error',
        timeout: 2000
      })
      store.state.overlay = false
    })
  }

  getCertificate(subdomain) {
    return axios.get(
      'https://' + subdomain + '.' + store.getters.cert_provider_domain + '/',
      {
        withCredentials: false,
        timeout: 300000
      }
    )
  }

  addCertificate(data) {
    return shakeApi.post('auth-events/add_cert/', data).then((res) => {
      document.cookie = 'shakecsrftoken=' + res.data.csrf
      store.commit('authUser', {
        access_token: res.data.access
      })
    })
  }

  addFido2(data) {
    var url = 'auth-events/add_fido2/'
    return shakeApi.post(url, data, {
      headers: authHeader(true)
    })
  }

  addEmail(data) {
    var url = 'auth-events/add_email/'
    return shakeApi.post(url, data)
  }
}

export default new UserService()
